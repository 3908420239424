import React, { useState, useRef, useEffect } from 'react';
import './AddToCalendarButton.scss';
import appleIcon from '../../assets/icons/apple-icon.svg';
import outlookIcon from '../../assets/icons/outlook-icon.svg';
import googleIcon from '../../assets/icons/google-calendar-icon.svg';
import microsoftIcon from '../../assets/icons/microsoft-icon.svg';

interface AddToCalendarButtonProps {
    title: string;
    description: string;
    location: string;
    startDate: Date;
    endDate: Date;
}

const AddToCalendarButton: React.FC<AddToCalendarButtonProps> = ({ title, description, location, startDate, endDate }) => {
    const [openedDropdown, setOpenedDropdown] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    const formatDate = (date: Date) => {
        return date.toISOString().replace(/-|:|\.\d\d\d/g, '');
    };

    const start = formatDate(startDate);
    const end = formatDate(endDate);

    const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(title)}&dates=${start}/${end}&details=${encodeURIComponent(description)}&location=${encodeURIComponent(location)}`;
    const outlookCalendarUrl = `https://outlook.live.com/owa/?path=/calendar/action/compose&subject=${encodeURIComponent(title)}&startdt=${start}&enddt=${end}&body=${encodeURIComponent(description)}&location=${encodeURIComponent(location)}`;
    const office365CalendarUrl = `https://outlook.office.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(title)}&startdt=${start}&enddt=${end}&body=${encodeURIComponent(description)}&location=${encodeURIComponent(location)}`;

    const icsContent = `BEGIN:VCALENDAR
    VERSION:2.0
    BEGIN:VEVENT
    SUMMARY:${title}
    DESCRIPTION:${description}
    LOCATION:${location}
    DTSTART:${start}
    DTEND:${end}
    END:VEVENT
    END:VCALENDAR`;

    const downloadIcsFile = () => {
        const blob = new Blob([icsContent], { type: 'text/calendar' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'event.ics';
        link.click();
        URL.revokeObjectURL(url);
    };

    const handleToggleDropdown = (event: React.MouseEvent) => {
        event.stopPropagation();
        setOpenedDropdown(!openedDropdown);
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (
            popupRef.current &&
            !popupRef.current.contains(event.target as Node) &&
            buttonRef.current &&
            !buttonRef.current.contains(event.target as Node)
          ) {
            setOpenedDropdown(false);
          }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='add-to-calendar-button'>
            <button ref={buttonRef} onClick={handleToggleDropdown}>
                <span className='button-text'>Add to calendar</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Icons">
                    <path id="Vector" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#EAFFF7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="Vector_2" d="M12 8V16" stroke="#EAFFF7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="Vector_3" d="M8 12H16" stroke="#EAFFF7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                </svg>
            </button>
            {openedDropdown && (
            <div className='popup' ref={popupRef}>
                <div>
                    <a href={googleCalendarUrl} target="_blank" rel="noopener noreferrer">
                    <img src={googleIcon} alt="google-icon" />
                    Google
                    </a>
                    <a href={outlookCalendarUrl} target="_blank" rel="noopener noreferrer">
                    <img src={outlookIcon} alt="outlook-icon" />
                    Outlook
                    </a>
                    <a href={office365CalendarUrl} target="_blank" rel="noopener noreferrer">
                    <img src={microsoftIcon} alt="microsoft-icon" />
                    Office 365
                    </a>
                    <button onClick={downloadIcsFile}>
                        <img src={appleIcon} alt="apple-icon" />
                    Apple
                    </button>
                </div>
            </div>
            )}
        </div>
    );
};

export default AddToCalendarButton;

import React from "react";

import "./LandingPage.scss";

const LandingPage: React.FC = () => {
    return (
        <div className="landing-page">
            <header className="component header">
                <h1>
                    <span><span className="header-color-text">Nofiasco</span> for your</span>
                    <span>Product Development</span>
                </h1>
            </header>
            <div className="page-body">
                Body
                Landing Page
                <div className="image-container component">
                    <img src="" alt="" />
                </div>
                <div className="spacer"></div>
            </div>
            <footer className="component">Footer</footer>
        </div>
    )
};

export default LandingPage;
import React from "react";
import { Link } from "react-router-dom";

import "./ComingSoonPage.scss";
import Countdown from "../../components/countdown/Countdown";
import AddToCalendarButton from '../../components/add-to-calendar-button/AddToCalendarButton';

import silpionLogo from '../../assets/logos/silpion-logo-black.png';
import teamsLogo from '../../assets/logos/teams-logo.svg';
import hoyerLogo from '../../assets/logos/hoyer-logo.svg';
import arrowRightIcon from '../../assets/icons/arrow-right-icon.svg';

const ComingSoonPage: React.FC = () => {
    const targetCountdownDate = '2024-07-05T12:00:00';

    const eventTitle = 'My Event';
    const eventDescription = 'This is the description of the event.';
    const eventLocation = 'Event Location';
    const eventStartDate = new Date('2024-12-31T10:00:00');
    const eventEndDate = new Date('2024-12-31T12:00:00');

    const agenda = [
        {
            time: '12:00',
            session: 'Arrival and registration'
        },
        {
            time: '12:30',
            session: 'Welcome and introduction'
        },
        {
            time: '12:45',
            session: 'Nofiasco: Keynote presentation'
        },
        {
            time: '15:15',
            session: 'QA Session'
        },
        {
            time: '16:00',
            session: 'Transfer to Solutions: Essentials'
        },
        {
            time: '17:00',
            session: 'Start of Solutions: Essentials'
        },
    ]

    return (
        <div className="coming-soon-page">
            <div className="header-blur-wrapper">
                <div className="header-blur">
                    <div className="header-blur-1"></div>
                    <div className="header-blur-2"></div>
                    <div className="header-blur-3"></div>
                    <div className="header-blur-4"></div>
                    <div className="header-blur-5"></div>
                    <div className="header-blur-6"></div>
                    <div className="header-blur-7"></div>
                    <div className="header-blur-8"></div>
                </div>
            </div>
            <header className="component">
                <h1>
                    NoFiasco
                </h1>
                <div className="add-calendar-button">
                    <AddToCalendarButton
                        title={eventTitle}
                        description={eventDescription}
                        location={eventLocation}
                        startDate={eventStartDate}
                        endDate={eventEndDate}
                    />
                </div>
            </header>
            <div className="page-title component">
                <p className="main-title">Super Solution</p>
                <h1>soft opening for your IoT Product</h1>
            </div>
            <section className="intro component">
                <p className="bold">
                    July 5
                    <span>2024</span>
                </p>
                <p>
                    TEAMS Design Hamburg GmbH
                    <span>Gertigstr. 10a</span>
                    <span>22303 Hamburg</span>
                </p>
                <p className="timeline">
                    Door opens at 1pm
                    <span>Main Session 2pm-4pm</span>
                    <span>Transfer to Silpion 4:30pm</span>
                </p>
            </section>
            <section className="logos-wrapper component">
                <div className="logo-container">
                    <img className="silpion-logo" src={silpionLogo} alt="silpion-logo" />
                </div>
                <div className="logo-container">
                    <img className="teams-logo" src={teamsLogo} alt="teams-logo" />
                </div>
                <div className="logo-container">
                    <img className="hoyer-logo" src={hoyerLogo} alt="hoyer-logo" />
                </div>
            </section>
            <div className="add-calendar-button-mobile component">
                <AddToCalendarButton
                    title={eventTitle}
                    description={eventDescription}
                    location={eventLocation}
                    startDate={eventStartDate}
                    endDate={eventEndDate}
                />
            </div>
            <div className="countdown-container">
                <Countdown targetCountdownDate={targetCountdownDate} />
            </div>
            
            <section className="experts component">
                <div className="description-wrapper">
                    <h4>Experts</h4>
                </div>
                <div className="details-wrapper">
                    <div className="company-wrapper">
                        <h4 className="title">Silpion</h4>
                        <div className="names-wrapper">
                            <div className="name-details">
                                <h1>Christian Schmidt</h1>
                                <p>Procurement</p>
                            </div>
                            <div className="name-details">
                                <h1>Mark Delling</h1>
                                <p>Development</p>
                            </div>
                        </div>
                    </div>
                    <div className="company-wrapper">
                        <h4 className="title">TEAMS Design</h4>
                        <div className="names-wrapper">
                            <div className="name-details">
                                <h1>Paul Stawenow</h1>
                                <p>Sustainability</p>
                            </div>
                            <div className="name-details">
                                <h1>Marco Kapetan</h1>
                                <p>Brand and Digital</p>
                            </div>
                            <div className="name-details">
                                <h1>Oliver Keller</h1>
                                <p>Strategic Design</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="address">
                <div className="header-wrapper component">
                    <div className="title-container">
                        <h4>Different directions one goal</h4>
                    </div>
                    {/* <div className="description-container">
                        <p></p>
                    </div> */}
                </div>
                <div className="details-wrapper component">
                    <div className="address-details-wrapper">
                        <p>
                            <span>TEAMS Design Hamburg GmbH</span>
                            <span>Gertigstr. 10a</span>
                            <span>22303 Hamburg</span>
                        </p>
                        <a href="https://maps.app.goo.gl/db5sMbPYTHpBttX28" target="_blank" rel="noreferrer" className="map-link">
                            Get directions
                            <img src={arrowRightIcon} alt="arrow-right" />
                        </a>
                    </div>
                    <div className="map-wrapper">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4737.39247803227!2d10.010899876958776!3d53.581037657136974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b1892af6896b01%3A0x31effda11b38a8f9!2sTeams%20Design%20Hamburg%20GmbH!5e0!3m2!1sen!2sde!4v1717763630724!5m2!1sen!2sde" width="600" height="450" style={{ border: 0 }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Google-Maps"></iframe>
                    </div>
                </div>
            </section>
            <div className="gradient-footer"></div>
            <footer>
                <div className="img-container">
                    <img src={silpionLogo} alt="silpion-logo" />
                </div>
                <div className="nav-wrapper">
                    <p>©2024 Nofiasco</p>
                    <Link to="/imprint" className="imprint-link">Imprint</Link>
                </div>
            </footer>
        </div>
    )
};

export default ComingSoonPage;
import React, { useEffect, useState } from 'react';
import './Countdown.scss';

interface CountdownProps {
    targetCountdownDate: string;
}

const Countdown: React.FC<CountdownProps> = ({ targetCountdownDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetCountdownDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        // seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents: JSX.Element[] = [];

  Object.keys(timeLeft).forEach((interval) => {
    timerComponents.push(
      <span key={interval} className='metric-wrapper'>
        <span className='value'>{timeLeft[interval as keyof typeof timeLeft]}</span>
        <span className='metric'>{interval}{' '}</span>
      </span>
    );
  });

  return (
    <div className='countdown'>
      {timerComponents.length ? timerComponents : <span>Time's up!</span>}
    </div>
  );
};

export default Countdown;

import React from 'react';
import './App.scss';
import ComingSoonPage from './pages/coming-soon-page/ComingSoonPage';
import LandingPage from './pages/landing-page/LandingPage';
import { BrowserRouter } from 'react-router-dom';

function App() {
  const [publishSite, setPublishSite] = React.useState(true)

  return (
    <BrowserRouter>
      <div className="App">
        {publishSite ? (
          <ComingSoonPage />
        ) : (
          <LandingPage />
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
